import React, { useContext, useEffect, useState, useCallback } from 'react';
import { css, styled, keyframes } from '@compiled/react';
import memoize from 'memoize-one';
import { useIntl } from 'react-intl-next';
import type { MutationUpdaterFn } from 'apollo-client';
import type { DataProxy } from 'apollo-cache';

import { token } from '@atlaskit/tokens';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { Inline, Text, xcss, Box, Flex } from '@atlaskit/primitives';
import DeleteIcon from '@atlaskit/icon/core/delete';

import {
	getRendererAnnotationEventEmitter,
	getEditorAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import {
	VIEW_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { CommentRenderer } from '@confluence/comment';
import {
	checkElementExpandInEditor,
	scrollCommentIntoView,
	scrollToElementInEditor,
} from '@confluence/comments-util';
import type {
	SitePermissionType,
	CommentInlineResolveProperties,
	ReactionsSummary,
} from '@confluence/inline-comments-queries';
import { fg } from '@confluence/feature-gating';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import { CommentType } from '@confluence/comments-data';
import type { FooterResolvedProperties } from '@confluence/comments-panel-queries';

import type { CommentPermissions, InlineCommentsMode, CommentAction } from './inlineCommentsTypes';
import { i18n } from './inlineCommentsi18n';
import { CommentActions } from './CommentActions';
import { CommentAuthor } from './CommentAuthor';
import { HighlightLineSVG } from './assets/HighlightLineSVG';
import { WithCurvedBranchingStyle } from './WithCurvedBranchingStyle';
import { useResizeObserver } from './hooks/useResizeObserver';

type CommentContainerProps = {
	isFocused?: boolean;
	isRemoving?: boolean;
	mode: InlineCommentsMode;
	isCommentsPanel?: boolean;
};

const fadeOut = keyframes({
	from: { opacity: 1 },
	to: { opacity: 0.5 },
});

const unreadIconStyle = css({ paddingTop: token('space.050') });

const unreadStyleInCommentsPanel = css({
	position: 'relative',
	top: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentContainer = styled.div<CommentContainerProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
  flex-direction: column;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
	padding: ${(props: CommentContainerProps) => {
		return props.mode === 'view-all'
			? token('space.400')
			: props.isCommentsPanel === true
				? 'inherit'
				: `${token('space.150')} ${token('space.200')}`;
	}};
  border-left: 0;
  border-top: 0;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  ${(props: CommentContainerProps) => Boolean(props.isRemoving) && `animation: ${fadeOut};`}
  animation-timing-function: linear;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  cursor: ${(props: CommentContainerProps) =>
		Boolean(props.isRemoving) ? 'not-allowed' : 'inherit'};
  outline: none;

${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  background: ${(props: CommentContainerProps) =>
		Boolean(props.isFocused) ? `${token('color.background.selected')}` : undefined};

  blockquote {
    color: ${token('color.text.subtlest')};
  }

  &:last-of-type {
    border-bottom: 0;
  }
`,
);

const withLeftBorderStyle = css({
	borderLeft: `1.5px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
	paddingBottom: token('space.100'),
});

const commentBodyStyles = css({
	marginTop: token('space.150'),
	marginLeft: token('space.200'),
	paddingLeft: token('space.300'),
	paddingRight: token('space.050'),
});

const commentReplyStyles = css({
	marginLeft: token('space.0'),
	paddingLeft: token('space.0'),
});

const warningMessageStyle = xcss({
	display: 'flex',
	fontStyle: 'italic',
	padding: 'space.150',
});

const xcssTranslucentStyle = xcss({
	opacity: '50%',
});

const cssTranslucentStyle = css({
	opacity: '50%',
});

const annotatedTextBodyStyle = xcss({
	borderWidth: '1.5px',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.100',
	padding: 'space.200',
	width: '100%',
	backgroundColor: 'elevation.surface',
	marginBottom: 'space.150',
});

const annotatedTextBodyHoverStyle = xcss({
	cursor: 'pointer',
});

const annotatedTextBodyWithPressedStyle = xcss({
	background: token('elevation.surface.pressed'),
});

// This snippet is being used in RepliesOpener.tsx
const dotStyles = xcss({
	height: token('space.100'),
	width: token('space.100'),
	backgroundColor: 'color.background.brand.bold',
	borderRadius: '50%',
	margin: 'space.050',
});

const replyBranchStyles = css({
	position: 'relative',
	// for the curvy border
	'&::before': {
		content: "''",
		position: 'absolute',
		left: '-24px',
		top: '16px',
		height: `var(--replyBranchHeight)`,
		bottom: '30px',
		borderLeft: `1.5px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		paddingLeft: token('space.200'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeContainer = styled.div({
	position: 'relative',
	height: '1px',
});

const highlightSvgBoxStyles = xcss({
	height: '22px',
	width: '2px',
});

type CommentBodyProps = {
	pageId: string;
	pageType: string;
	avatarUrl?: string;
	commentId: string;
	content: string;
	date: string;
	displayName?: string | null;
	permissions: CommentPermissions;
	dateUrl?: string;
	userId?: string | null;
	deleteComment?: () => void;
	editComment?: () => void;
	resolveComment?: () => void;
	isReply: boolean;
	parentCommentId?: string | null;
	isFocused?: boolean;
	shouldScrollIntoView?: boolean;
	isRemoving?: boolean;
	numReplies?: number;
	isFabricPage?: boolean;
	isCommentActive?: boolean;
	isCurrentUserAnonymous?: boolean;
	mode: InlineCommentsMode;
	onRendered?: () => void;
	onClose?: () => void;
	shouldAutofocus?: boolean;
	onAutoFocused?: () => void;
	permissionType?: SitePermissionType;
	maxHeight?: number;
	fadeOutHeight?: number;
	onComplete?: () => void;
	supportedActions?: CommentAction[];
	isUnread?: boolean;
	unreadCommentRef?: React.RefObject<HTMLDivElement>;
	isCommentsPanel?: boolean;
	replyCount?: number;
	isHovered?: boolean;
	isResolvedByAnotherUser?: boolean;
	resolveProperties?: CommentInlineResolveProperties | FooterResolvedProperties;
	annotationId?: string;
	annotatedText?: string | null;
	reopenComment?: () => void;
	replyToComment?: (commentId: string) => void;
	inheritedReactionsData?: ReactionsSummary | null;
	reactionsCacheUpdateFn?: (
		emojiId: string,
		actionType: 'add' | 'delete',
		contentId: string,
		containerId?: string,
		cache?: DataProxy,
	) => MutationUpdaterFn<any> | void;
	isDeletedByAnotherUser?: boolean;
	currentlySelectedCommentMarkerRef?: string;
	setCurrentlySelectedCommentMarkerRef?: (markerRef: string) => void;
	isAnnotatedTextPressed?: boolean;
	setAnnotatedTextPressed?: (state: boolean) => void;
	commentType: CommentType;
	adjustForNestedReplies?: boolean;
	hideBranchingStyle?: boolean;
	canAddComments?: boolean;
	isThreadHovered?: boolean;
	isParentCommentOpen?: boolean;
};

// Parse content to JSON or Fail view inline experience and return null
const parseContent = memoize((content, experienceTracker) => {
	if (!content?.length) return null;

	try {
		return JSON.parse(content);
	} catch (error) {
		experienceTracker.stopOnError({
			name: VIEW_INLINE_COMMENT_EXPERIENCE,
			error,
		});
		return null;
	}
});

export const CommentBody = ({
	pageId,
	pageType,
	avatarUrl,
	commentId,
	content,
	date,
	displayName,
	permissions,
	dateUrl,
	userId,
	deleteComment,
	editComment,
	resolveComment,
	isReply,
	parentCommentId,
	isFocused,
	shouldScrollIntoView,
	isRemoving,
	numReplies,
	isFabricPage,
	isCommentActive,
	isCurrentUserAnonymous,
	mode,
	onRendered,
	onClose,
	shouldAutofocus,
	onAutoFocused,
	permissionType,
	maxHeight,
	fadeOutHeight,
	onComplete,
	supportedActions = ['edit', 'delete', 'resolve'],
	isUnread = false,
	unreadCommentRef,
	isCommentsPanel = false,
	isHovered,
	isResolvedByAnotherUser = false,
	resolveProperties,
	annotationId,
	annotatedText,
	reopenComment,
	replyToComment,
	inheritedReactionsData,
	reactionsCacheUpdateFn,
	isDeletedByAnotherUser,
	currentlySelectedCommentMarkerRef,
	setCurrentlySelectedCommentMarkerRef,
	isAnnotatedTextPressed,
	setAnnotatedTextPressed,
	commentType = CommentType.INLINE,
	adjustForNestedReplies = false,
	hideBranchingStyle = false,
	canAddComments = true,
	isThreadHovered,
	isParentCommentOpen,
}: CommentBodyProps) => {
	const { formatMessage } = useIntl();
	const [showingCopyLinkButton, setShowingCopyLink] = useState(false);
	const [hasFallbackContent, setHasFallbackContent] = useState(false);
	const [overflowPortal, setOverflowPortal] = useState<HTMLDivElement | undefined>(undefined);
	const [isAnnotatedTextHovered, setIsAnnotatedTextHovered] = useState(false);
	const pageMode = useGetPageMode();
	const eventEmitter =
		pageMode === PageMode.EDIT || pageMode === PageMode.LIVE
			? getEditorAnnotationEventEmitter()
			: getRendererAnnotationEventEmitter();

	const isSelected = currentlySelectedCommentMarkerRef === annotationId;

	const experienceTracker = useContext(ExperienceTrackerContext);

	const [containerRef, replyBranchHeight] = useResizeObserver();

	useEffect(() => {
		/* We only need to scroll for focused replies as parent comments will have scrolled when selected */
		if (shouldScrollIntoView && isFocused && containerRef && containerRef.current) {
			scrollCommentIntoView({ commentElement: containerRef.current, isFabricPage });
		}
	}, [containerRef, isFocused, shouldScrollIntoView, isFabricPage]);

	useEffect(() => {
		if (shouldAutofocus) {
			containerRef.current && containerRef.current.focus();
			onAutoFocused && onAutoFocused();
		}
	}, [containerRef, shouldAutofocus, onAutoFocused]);

	const adf = parseContent(content, experienceTracker);

	const wrapperLabel = formatMessage(i18n.a11yCommentLabel, {
		username: displayName,
	});

	const onCommentContentFallback = useCallback(() => setHasFallbackContent(true), []);

	const handleRef = (portalDiv: HTMLDivElement) => {
		/* do a check to prevent from re-running setPopupPortal multiple times*/
		if (!overflowPortal && portalDiv) {
			setOverflowPortal(portalDiv);
		}
	};

	const handleMouseEventOnWrapper = (eventType: 'enter' | 'leave') => () => {
		setShowingCopyLink(eventType === 'enter');
	};

	const commentRemovedByAnotherUser = isDeletedByAnotherUser || isResolvedByAnotherUser;
	const shouldShowOverflowMenu = supportedActions.length > 0;

	const showRepliesWithCurvedBorders =
		isCommentsPanel && isReply && fg('confluence_frontend_comments_panel_v2');

	const getHighlightBackgroundColor = () => {
		if (isAnnotatedTextPressed) {
			return token('color.background.accent.yellow.subtle.hovered');
		}
		if (isSelected) {
			return token('color.background.accent.yellow.subtle');
		}
		if (
			isAnnotatedTextHovered ||
			(isThreadHovered && fg('confluence_frontend_comments_panel_v2'))
		) {
			return token('color.background.accent.yellow.subtlest.hovered');
		}
		return 'inherit';
	};

	const handleMouseUp = () => {
		if (annotationId && setCurrentlySelectedCommentMarkerRef) {
			setCurrentlySelectedCommentMarkerRef(annotationId);
			const commentElement = document.getElementById(annotationId);
			if (commentElement && isParentCommentOpen) {
				if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
					scrollToElementInEditor({ targetElement: commentElement, viewingRoomOffset: -300 });
					checkElementExpandInEditor(commentElement);
					eventEmitter.emit('setselectedannotation', annotationId);
				} else {
					eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, {
						annotationId,
					});
					const commentThreadContainerId = `comment-thread-${annotationId}-container`;
					scrollCommentIntoView({
						commentElement,
						isFabricPage: true,
						useInstantScroll: false,
						isAbove: false,
						commentThreadContainerId,
						isCommentsPanel,
					});
				}
			}
			setAnnotatedTextPressed && setAnnotatedTextPressed(false);
		}
	};

	const handleMouseEnter = (): void => {
		if (!fg('confluence_frontend_comments_panel_v2')) {
			if (annotationId) {
				if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
					eventEmitter.emit('sethoveredannotation', annotationId);
				} else {
					eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_HOVERED, {
						annotationId,
					});
				}
				setIsAnnotatedTextHovered(true);
			}
		}
	};

	const handleMouseLeave = (): void => {
		if (!fg('confluence_frontend_comments_panel_v2')) {
			if (annotationId) {
				if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
					eventEmitter.emit('removehoveredannotation', annotationId);
				} else {
					eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_HOVERED, {
						annotationId,
					});
				}
				setIsAnnotatedTextHovered(false);
			}
		}
	};

	const shouldShowCommentContentContext = isCommentsPanel && !isReply;

	const showOriginalContentDeletedForDanglingComment =
		(resolveProperties as CommentInlineResolveProperties)?.resolvedByDangling &&
		fg('confluence_frontend_dangling_comments');

	return (
		<CommentContainer
			ref={containerRef}
			data-comment-id={commentId}
			data-testid={commentId}
			isFocused={isFocused}
			isRemoving={isRemoving}
			mode={mode}
			isCommentsPanel={isCommentsPanel}
			aria-label={wrapperLabel}
			// eslint-disable-next-line jsx-a11y/aria-role
			role="comment"
			tabIndex={-1}
			onMouseEnter={handleMouseEventOnWrapper('enter')}
			onMouseLeave={handleMouseEventOnWrapper('leave')}
			css={{ position: 'relative ' }}
		>
			<div ref={unreadCommentRef}>
				{mode !== 'view' && (
					<RelativeContainer>
						<div data-testid="overflow-container" ref={handleRef} />
					</RelativeContainer>
				)}
				<div
					css={
						isReply &&
						isCommentsPanel &&
						!hideBranchingStyle &&
						fg('confluence_frontend_comments_panel_v2') &&
						replyBranchStyles
					} // We need to adjust the size of the border if we have a nested reply scroller below this
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
					style={
						!hideBranchingStyle
							? ({
									'--replyBranchHeight': `${replyBranchHeight + (adjustForNestedReplies ? 53 : 0)}px`,
								} as React.CSSProperties)
							: {}
					}
				>
					<WithCurvedBranchingStyle
						showDefaultStyles={showRepliesWithCurvedBorders}
						customStyles={isCommentsPanel ? { height: '20px' } : {}}
					>
						<Inline spread="space-between">
							<CommentAuthor
								commentMode="view"
								userId={userId}
								date={date}
								displayName={displayName}
								avatarUrl={avatarUrl}
								commentDateUrl={dateUrl}
								commentId={isCurrentUserAnonymous ? commentId : null}
								permissionType={permissionType}
								isInactiveComment={Boolean(mode === 'view-all' && !isCommentActive)}
								showCopyLink={showingCopyLinkButton}
								resolveProperties={resolveProperties}
								isCommentsPanel={isCommentsPanel}
								isGeneralComments={commentType === CommentType.GENERAL}
								isReply={isReply}
							/>
							{isUnread && !isHovered && (
								<div
									css={[
										unreadIconStyle,
										isCommentsPanel &&
											fg('confluence_frontend_comments_panel_v2') &&
											unreadStyleInCommentsPanel,
									]}
									data-testid={`unread-comment-indicator-${commentId}`}
								>
									<Box xcss={dotStyles} />
								</div>
							)}
						</Inline>
					</WithCurvedBranchingStyle>
				</div>
				<div
					css={[
						isCommentsPanel && fg('confluence_frontend_comments_panel_v2') && commentBodyStyles,
						isCommentsPanel &&
							isReply &&
							fg('confluence_frontend_comments_panel_v2') &&
							commentReplyStyles,
						isCommentsPanel &&
							!isReply &&
							!hideBranchingStyle &&
							(numReplies !== 0 || canAddComments) &&
							fg('confluence_frontend_comments_panel_v2') &&
							withLeftBorderStyle,
					]}
				>
					{shouldShowCommentContentContext && (
						<>
							{resolveProperties?.resolved &&
								!fg('confluence_frontend_comments_panel_v2') &&
								annotatedText && (
									<Inline
										space="space.150"
										alignBlock="center"
										xcss={[isResolvedByAnotherUser && xcssTranslucentStyle]}
										testId="highlight-text"
									>
										<Box xcss={highlightSvgBoxStyles}>
											<HighlightLineSVG />
										</Box>
										<Text color="color.text.subtle" size="medium" maxLines={1}>
											{annotatedText}
										</Text>
									</Inline>
								)}
							{fg('confluence_frontend_comments_panel_v2') && (
								<Inline
									space="space.150"
									alignBlock="center"
									xcss={[isResolvedByAnotherUser && xcssTranslucentStyle]}
									testId="highlight-text"
								>
									<Box
										data-testId="annotated-section-for-inlineComment"
										xcss={[
											annotatedTextBodyStyle,
											!isResolvedByAnotherUser &&
												isAnnotatedTextHovered &&
												!isAnnotatedTextPressed &&
												annotatedTextBodyHoverStyle,
											!isResolvedByAnotherUser &&
												isAnnotatedTextPressed &&
												annotatedTextBodyWithPressedStyle,
										]}
										onMouseEnter={handleMouseEnter}
										onMouseLeave={handleMouseLeave}
										onMouseDown={() => {
											if (annotationId) {
												setAnnotatedTextPressed && setAnnotatedTextPressed(true);
											}
										}}
										onMouseUp={handleMouseUp}
									>
										{showOriginalContentDeletedForDanglingComment ? (
											<Flex gap="space.100" alignItems="center">
												<DeleteIcon label="" color={token('color.icon.subtlest')} />
												<Text size="small" maxLines={3} color="color.text.subtle">
													{formatMessage(i18n.originalContentDeleted)}
												</Text>
											</Flex>
										) : (
											annotatedText && (
												<Text size="small" maxLines={3}>
													<span
														style={{
															background: getHighlightBackgroundColor(),
															borderBottom:
																isAnnotatedTextPressed ||
																isSelected ||
																isAnnotatedTextHovered ||
																(isThreadHovered && fg('confluence_frontend_comments_panel_v2'))
																	? `2px solid ${token('color.icon.accent.yellow')}`
																	: '',
														}}
													>
														{annotatedText}
													</span>
												</Text>
											)
										)}
									</Box>
								</Inline>
							)}
						</>
					)}
					{adf && (
						<>
							<div css={[isCommentsPanel && commentRemovedByAnotherUser && cssTranslucentStyle]}>
								<CommentRenderer
									commentId={commentId}
									adf={adf}
									onContentFallback={onCommentContentFallback}
									isTruncatedContent={mode === 'view-all' && !isCommentActive}
									onRendered={onRendered}
									onComplete={onComplete}
									maxHeight={maxHeight}
									fadeOutHeight={fadeOutHeight}
									isInlineComment
									isCommentsPanel={isCommentsPanel}
								/>
							</div>
							{isDeletedByAnotherUser && (
								<Box xcss={warningMessageStyle}>
									<Text weight="bold">{formatMessage(i18n.deletedCommentActionLabel)}</Text>
								</Box>
							)}
						</>
					)}
				</div>
				{shouldShowOverflowMenu && (
					<CommentActions
						pageId={pageId}
						pageType={pageType}
						commentId={commentId}
						permissions={permissions}
						isReply={isReply}
						parentCommentId={parentCommentId}
						deleteComment={deleteComment}
						editComment={editComment}
						resolveComment={resolveComment}
						restrictEdit={hasFallbackContent}
						restrictDelete={Boolean(numReplies)}
						numReplies={numReplies}
						isCommentActive={isCommentActive}
						mode={mode}
						commentDateUrl={dateUrl}
						onClose={onClose}
						overflowMenuPortal={overflowPortal}
						supportedActions={supportedActions}
						isCommentsPanel={isCommentsPanel}
						isHovered={isHovered}
						annotationId={annotationId}
						resolveProperties={resolveProperties}
						reopenComment={reopenComment}
						replyToComment={replyToComment}
						isUnread={isUnread}
						inheritedReactionsData={inheritedReactionsData}
						reactionsCacheUpdateFn={reactionsCacheUpdateFn}
						isResolvedByAnotherUser={isResolvedByAnotherUser}
						commentType={commentType}
						hideBranchingStyle={hideBranchingStyle}
						canAddComments={canAddComments}
						isDeletedByAnotherUser={isDeletedByAnotherUser}
						isSelected={isSelected}
						isParentCommentOpen={isParentCommentOpen}
					/>
				)}
			</div>
		</CommentContainer>
	);
};
