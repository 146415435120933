import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import type { SizeType } from '@atlaskit/avatar/types';
import { Text, Inline } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import PageIcon from '@atlaskit/icon/core/page';

import { ProfileAvatar, ProfileLink } from '@confluence/profile';
import { TimeAgo } from '@confluence/time-ago';
import { ExternalCollaboratorUserLozenge } from '@confluence/external-collab-ui';
import type { FooterResolvedProperties } from '@confluence/comments-panel-queries';
import {
	InlineCommentAuthorQuery,
	SitePermissionTypeValues,
	UserQuery,
} from '@confluence/inline-comments-queries';
import type {
	SitePermissionType,
	AuthorQueryType,
	InlineCommentAuthorQuery_content_nodes_history_createdBy_KnownUser,
	InlineCommentAuthorQuery_content_nodes_history_createdBy_User,
	AuthorQueryVariables,
	UserQueryType,
	UserQueryVariables,
	CommentInlineResolveProperties,
} from '@confluence/inline-comments-queries';
import { fg } from '@confluence/feature-gating';

import type { CommentMode } from './inlineCommentsTypes';
import { CopyLinkButton } from './CopyLinkButton';
import { i18n } from './inlineCommentsi18n';

type CommentAuthorProps = {
	commentMode: CommentMode;
	userId?: string | null;
	avatarUrl?: string | null;
	displayName?: string | null;
	date?: string | null;
	size?: SizeType;
	commentDateUrl?: string;
	commentId?: string | null;
	permissionType?: SitePermissionType;
	isInactiveComment?: boolean;
	showCopyLink?: boolean;
	resolveProperties?: CommentInlineResolveProperties | FooterResolvedProperties;
	isCommentsPanel?: boolean;
	isGeneralComments?: boolean;
	isReply?: boolean;
};

type User =
	| InlineCommentAuthorQuery_content_nodes_history_createdBy_KnownUser
	| InlineCommentAuthorQuery_content_nodes_history_createdBy_User;

type AuthorContainerProps = {
	commentMode: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const AuthorContainer = styled.div<AuthorContainerProps>(`
  display: flex;
  align-items: center;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  margin-left: ${(props: AuthorContainerProps) =>
		props.commentMode === 'reply' ? '-2px' : 'inherit'};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  padding-bottom: ${(props: AuthorContainerProps) =>
		props.commentMode !== 'view' ? '8px;' : undefined};

  > a {
    color: ${token('color.text')} !important;

    &:visited,
    &:hover {
      color: ${token('color.text')} !important;
      text-decoration: none !important;
    }
  }
  overflow: "hidden";
  `);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const AvatarWrapper = styled.div<{ isCommentsPanel: boolean }>(`
	display: 'flex';
	${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
	padding-right: ${(props: { isCommentsPanel: boolean }) =>
		props.isCommentsPanel ? token('space.075') : token('space.100')};
	`);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
  flex-direction: column;
  > a {
    color: ${token('color.text')} !important;
	font: ${token('font.body')};
    &:visited,
    &:hover {
      color: ${token('color.text')} !important;
    }
    &:focus-visible {
      outline: 2px solid ${token('color.border.focused')};
    }
  }
  > span.comment-timestamp {
    font: ${token('font.body.small')};
    color: ${token('color.text.subtle')};
    margin: 0;
    background-color: transparent;
    border: none;

    > a {
      color: ${token('color.text.subtle')};

      &:visited,
      &:hover {
        color: ${token('color.text.subtle')};
      }
    }
  }
`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProfileLinkContainer = styled(Container)({
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.span({
	paddingLeft: `${token('space.100')}`,
	verticalAlign: 'top',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateContainer = styled.div(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > span {
    font: ${token('font.body.small')};
    color: ${token('color.text.subtle')};
    margin: 0;
    background-color: transparent;
    border: none;

    > a {
      color: ${token('color.text.subtle')};

      &:visited,
      &:hover {
        color: ${token('color.text.subtle')};
      }
    }
  }

  > div {
    button:focus {
      box-shadow: 0 0 0 2px ${token('color.border.focused')};
    }
  }
`,
);

export const CommentAuthor: FC<CommentAuthorProps> = ({
	commentMode,
	userId,
	date,
	size,
	displayName,
	avatarUrl,
	commentDateUrl,
	commentId,
	permissionType,
	isInactiveComment,
	showCopyLink = false,
	resolveProperties,
	isCommentsPanel = false,
	isGeneralComments = false,
	isReply = false,
}) => {
	const { data: authorData } = useQuery<AuthorQueryType, AuthorQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		InlineCommentAuthorQuery,
		{
			variables: { commentId: commentId! },
			fetchPolicy: 'cache-and-network',
			skip: Boolean(userId || !commentId),
		},
	);

	const { data: userData } = useQuery<UserQueryType, UserQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UserQuery,
		{
			variables: { id: userId! },
			fetchPolicy: 'cache-and-network',
			// Skip the query if data is provided or the user is anonymous
			skip: Boolean((avatarUrl && displayName) || !userId || commentId),
		},
	);

	const author = (authorData?.content?.nodes || [])[0]?.history?.createdBy;
	const userAvatar = (
		author ? author.profilePicture?.path : (userData?.user?.photos || [])[0]?.value
	) as string | undefined;
	const userDisplayName = author ? author.displayName : userData?.user?.displayName;
	const userAccountId = (author as User)?.accountId;

	const linkToComment = `${window.location.origin}/wiki${commentDateUrl}`;
	const { formatMessage } = useIntl();

	const renderCopyButton = () => {
		const resolvedInfo = isCommentsPanel ? (
			<Tooltip
				content={formatMessage(i18n.resolvedByTooltipTextCommentsPanel, {
					username: resolveProperties?.resolvedUser,
				})}
				position="top"
			>
				<Text color="color.text.subtlest" size="small" maxLines={2}>
					{formatMessage(i18n.resolvedDateInfoText, {
						friendlyDate: resolveProperties?.resolvedFriendlyDate,
					})}
				</Text>
			</Tooltip>
		) : (
			<Tooltip content={resolveProperties?.resolvedFriendlyDate}>
				<Text color="color.text.subtlest" size="small" maxLines={2}>
					{formatMessage(i18n.resolvedByInfoText, {
						username: resolveProperties?.resolvedUser,
						friendlyDate: resolveProperties?.resolvedFriendlyDate,
					})}
				</Text>
			</Tooltip>
		);

		const renderCopyLinkButton =
			commentDateUrl &&
			((isCommentsPanel && !fg('confluence_frontend_comments_panel_v2')) || !isCommentsPanel);
		return (
			<DateContainer>
				{resolveProperties?.resolved ? (
					resolvedInfo
				) : (
					<span>
						<Inline space="space.050">
							<TimeAgo date={date!} />
							{isGeneralComments &&
								!isReply &&
								fg('confluence-frontend-comments-panel-design-update') && (
									<Tooltip content={formatMessage(i18n.generalCommentTooltip)}>
										<PageIcon
											color={token('color.icon.subtlest')}
											testId="general-comment-page-icon"
											label={formatMessage(i18n.generalCommentTooltip)}
										/>
									</Tooltip>
								)}
						</Inline>
					</span>
				)}

				{renderCopyLinkButton && (
					<CopyLinkButton visible={showCopyLink} textToCopy={linkToComment} />
				)}
			</DateContainer>
		);
	};

	const isDateShown = commentMode === 'view' && date;
	const name = displayName || userDisplayName || '';
	return (
		<AuthorContainer commentMode={commentMode} data-cy="comment-author">
			<AvatarWrapper isCommentsPanel={isCommentsPanel}>
				<ProfileAvatar
					userId={userId}
					src={avatarUrl || userAvatar}
					trigger="click"
					size={size}
					name={name}
				/>
			</AvatarWrapper>
			<Container>
				<ProfileLinkContainer>
					{/* Show Dummy Link in Inactive Mode for View-All*/}
					{isInactiveComment ? (
						<span data-testid="dummy-profile-link">{displayName || userDisplayName}</span>
					) : (
						<ProfileLink
							fullName={(displayName || userDisplayName)!}
							userId={userId || userAccountId}
							style={{
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								wordBreak: 'break-word',
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								color: token('color.text'),
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								fontWeight: token('font.weight.medium'),
							}}
							isUnderlinedByDefault={false}
						/>
					)}
					{permissionType === SitePermissionTypeValues.EXTERNAL ? (
						<LozengeWrapper>
							<ExternalCollaboratorUserLozenge />
						</LozengeWrapper>
					) : null}
				</ProfileLinkContainer>
				{isDateShown && renderCopyButton()}
			</Container>
		</AuthorContainer>
	);
};
